import React from 'react';

// Components
import MarketPlace from '../components/MarketPlace';

const MarketPage = () => {
  return (
    <>
      <div className="main">
        <MarketPlace />
      </div>
    </>
  );
};

export default MarketPage;
